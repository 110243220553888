import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	chatFooter: {
		position: "static",
		bottom: "75px",
		right: 0,
		left: 0,
		height: "43px",
		fontSize: "12px",
		margin: "auto",
		marginBottom: "0px",
		color: "var(--color-light-grey)",
	},
});
export default function ChatFooter() {
	const classes = useStyles();
	return (
		<footer className={classes.chatFooter}>
			<p>
				Powered By <strong>SAIL</strong>
			</p>
		</footer>
	);
}
