import { useStateValue } from "../context/state/provider";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
	applyCustomColorSettings,
	resetDefaultColors,
} from "../helpers/colorHelpers";
import { initialState } from "../context/state/reducer";
import { VISIBILITY } from "../constants/visibility.constants";

const useStyles = makeStyles((theme) => ({
	settingsPanel: {
		position: "absolute",
		overflowY: "auto",
		overflowX: "hidden",
		top: "75px",
		bottom: "40px",
		left: 0,
		right: 0,
		animation: "fadeInAnimation 0.4s ease",
		padding: "10px ",
		color: "var(--color-dark-grey)",

		"&h2": {
			color: "blue",
		},
	},
	colorPicker: {
		width: "200px",
		height: "40px",
		marginTop: "10px",
		border: "none",
		backgroundColor: "transparent",
		padding: "0",
		"&:hover": {
			cursor: "pointer",
		},
	},
	title: {
		fontSize: "20px",
		fontWeight: 500,
		marginBottom: "30px",
	},
}));
export default function SettingsPanel() {
	const classes = useStyles();
	const [{ colorSettings, visibility, admin }, dispatch] = useStateValue();

	const handleColorChange = (e, type) => {
		if (type === "PRIMARY") {
			dispatch({
				type: "COLOR_SETTINGS",
				colorSettings: {
					...colorSettings,
					accent: e.target.value,
					primary: e.target.value,
				},
			});
			if (visibility === VISIBILITY.STANDARD)
				applyCustomColorSettings(type, e.target.value);
		} else if (type === "ACCENT") {
			dispatch({
				type: "COLOR_SETTINGS",
				colorSettings: { ...colorSettings, accent: e.target.value },
			});
			applyCustomColorSettings(type, e.target.value);
		} else {
			dispatch({
				type: "COLOR_SETTINGS",
				colorSettings: { ...colorSettings, contrast: e.target.value },
			});
			if (visibility === VISIBILITY.HIGH_CONTRAST)
				applyCustomColorSettings(type, e.target.value);
		}
	};

	const handleColorReset = () => {
		dispatch({
			type: "COLOR_SETTINGS",
			colorSettings: initialState.colorSettings,
		});
		resetDefaultColors();
	};
	const toggleFeedbackSetting = () => {
		dispatch({
			type: "ADMIN_SETTINGS",
			admin: { ...admin, adminUser: true, feedbackOn: !admin.feedbackOn },
		});
	};

	return (
		<div className={classes.settingsPanel}>
			<Box display="flex" flexDirection="column" alignItems="center">
				<br />
				<br />
				<br />
				<Box margin={3}>
					<label className={classes.title} htmlFor="primary">
						QA User Role{" "}
					</label>
					<br />
					<FormControlLabel
						control={
							<Switch
								name="feedbackToggle"
								inputProps={{
									"aria-label": "feedback toggle checkbox",
								}}
								onChange={() => toggleFeedbackSetting()}
								checked={admin.feedbackOn}
							/>
						}
					/>
				</Box>
			</Box>
		</div>
	);
}
