import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStateValue } from "../context/state/provider";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import UserModal from "../components/UserModal";
import HeaderTitle from "../components/HeaderTitle";
import TextIcon from "../images/textIcon.svg";
import RefreshIcon from "../images/refreshIcon.svg";
import UserIcon from "../images/userIcon.svg";
import { VIEW } from "../constants/view.constants";
import { red } from "@material-ui/core/colors";
import { firestore, auth } from "../services/firebase/firebase";
import writeGreetingsOptions from "../helpers/writeGreetingsOptions";
import writeForm from "../helpers/writeForm"
import { useAuthState } from "react-firebase-hooks/auth";

const useStyles = makeStyles({
	appbar: {
		width: "100%",
		color: "var(--custom-client-text)",
		height: "75px",
		padding: "0px 15px",
		borderRadius: "6px 6px 0 0",
		transition: "background-color 0.3s ease-in",
	},
	'@media (max-height: 180px)': {
		appbar: {
			display: "none",
		}
	},
	icon: {
		color: "#ffffff",
		height: "28px",
		width: "28px",
	},
	toolbar: {
		padding: 0,
	},
	iconButton: {
		padding: "10px",
		alignItems: 'center',
		justifyContent: 'center',
		flex: '0 0 auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
		transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		color: 'inherit',
		borderRadius: '50%',
		border: '0',
		cursor: 'pointer',
		margin: '0',
		display: 'inline-flex',
		outline: '0',
		position: 'relative',
		userSelect: 'none',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		'-webkit-appearance': 'none',
		'-webkit-tap-highlight-color': 'transparent',

		"&:hover": {
			background: "transparent",
			opacity: "0.8",
		},
		"&:disabled": {
			background: "transparent",
			opacity: "0.8",

			color: 'rgba(0, 0, 0, 0.26)',
			cursor: 'default',
			'pointer-events': 'none',
		},
		"&:focus": {
			background: "rgba(0,0,0,0.2)",
		},
	},
	iconButtonDisabled: {
		padding: "10px",
		alignItems: 'center',
		justifyContent: 'center',
		flex: '0 0 auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
		transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		borderRadius: '50%',
		border: '0',
		margin: '0',
		display: 'inline-flex',
		outline: '0',
		position: 'relative',
		userSelect: 'none',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		'-webkit-appearance': 'none',
		'-webkit-tap-highlight-color': 'transparent',
		background: "transparent",
		opacity: "0.8",
		color: 'rgba(0, 0, 0, 0.26)',
		cursor: 'default',
		'pointer-events': 'none',

		"&:hover": {
			background: "transparent",
			opacity: "0.8",
		},
		"&:disabled": {
			background: "transparent",
			opacity: "0.8",

			color: 'rgba(0, 0, 0, 0.26)',
			cursor: 'default',
			'pointer-events': 'none',
		},
		"&:focus": {
			background: "rgba(0,0,0,0.2)",
		},
	},
	"@media (max-width: 300px)": {
		icon: {
			color: "#ffffff",
			height: "25px",
			width: "25px",
		},
		iconButton: {
			margin: "8px",
			padding: "0",
		},
	},
});

function extractTokenFromUrl() {
	// Get the URL query parameters
	const queryString = window.location.search;

	// Parse the query string into an object
	const queryParams = new URLSearchParams(queryString);

	// Get the value of the 'token' parameter
	const token = queryParams.get('token');
	console.log(token);
	return token;
}

export default function Navbar({ user, brandingTitle, firestore, chatbotId }) {
	const classes = useStyles();
	const [{ view }, dispatch] = useStateValue(); // user,
	const [modalOpen, setModalOpen] = useState(false);
	const [authState, authLoading, authError] = useAuthState(auth);
	console.log("authState", authState);
	console.log("user", user);
	const handleAccessibilityButton = () => {
		dispatch({
			type: "SET_VIEW",
			view: VIEW.ACCESSIBILITY,
		});
	};

	const handleClickOpen = () => {
		setModalOpen(true);
	};

  const handleClose = (value) => {
    if (view === VIEW.CHATROOM) {
    }
    setModalOpen(false);
  };

	const handleLogout = () => {
		console.log("handleLogout")
		//Set logginOut state to hide input field and error message if any while the logout and fetch of new greeting message is completed
		dispatch({
			type: "LOGGING_OUT",
			loggingOut: true,
		});
		// Auth change triggers firestore room setter in app.js useEffect
		// This generates a New Room/Random ID
		// const loggedInUid = auth.currentUser.uid;
		// console.log("loggedInUid", loggedInUid);
		// firestore
		// 	.collection("rooms")
		// 	.doc(loggedInUid)
		// 	.set({
		// 		provider: JSON.parse(sessionStorage.getItem("CLIENT")).defaultAgent,
		// 		providerId: JSON.parse(sessionStorage.getItem("CLIENT")).defaultAgentId,
		// 		pendingMessages: 0,
		// 		chatbotId: JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId,
		// 		prevProvider: "",
		// 		prevProviderId: "",
		// 	}, { merge: true })
		// 	.then(() => {
				sessionStorage.setItem("prevProvider", JSON.parse(sessionStorage.getItem("CLIENT")).defaultAgent);
				auth.signOut()
					.then(() => {
						console.log("Signout Successful");

						dispatch({
							type: "ADMIN_SETTINGS",
							admin: {
								adminUser: false,
								feedbackOn: false,
							},
						});
						localStorage.removeItem("userType");

						//Before closing the modal login as anonymous user
						auth.signInAnonymously()
							.then((currentUser) => {
								console.log({ currentUser });
								const snapShot = async () => {
									await firestore
										.collection("rooms")
										.doc(currentUser.user.uid + "***" + chatbotId)
										.get()
								}

								if (!snapShot.exists) {
									// console.log("!snapShot.exists");
									// console.log("CLIENT", JSON.parse(sessionStorage.getItem("CLIENT")));
									firestore
										.collection("rooms")
										.doc(currentUser.user.uid)
										.set({
											chatbotId: chatbotId, //JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId,
											provider: JSON.parse(sessionStorage?.getItem("CLIENT"))?.defaultAgent,
											providerId: JSON.parse(sessionStorage?.getItem("CLIENT"))?.defaultAgentId,
											// pendingMessages: 0,
										}, { merge: true })
										.then(() => {
											//get chatbot info to check in the form need to be display immediatly
											// let clientId = getQueryVariable("id")
											firestore
												.collection("chatbots")
												// .doc(JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId)
												.doc(chatbotId)
												.get()
												//****************************************
												.then(async (res) => {
													const chatbotDocVal = res.data()
													console.log("GET CHATBOT INFO", chatbotDocVal);
													//If the trigger for form is first request
													if (chatbotDocVal.trigger === "After First Request") {
														// writeForm(currentUser.user.uid, JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId)
														writeForm(currentUser.user.uid, chatbotId)
													} else {
														// writeGreetingsOptions(currentUser.user.uid, JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId)
														writeGreetingsOptions(currentUser.user.uid, chatbotId)
													}
													// writeGreetingsOptions(currentUser.user.uid, JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId)
												})
												.catch((err) => {
													console.log("ERROR", err);
												});
										})
										.catch((error) => {
											console.error("Error writing document: ", error);
										});
								}
								//check userType in local storage
								localStorage.removeItem("userType");

								dispatch({
									type: "USER",
									user: {
										...user,
										id: currentUser.user.uid,
										loggedIn: false,
										name: null,
									},
								});

								dispatch({
									type: "SET_VIEW",
									view: VIEW.CHATROOM,
								});

								// onClose(); //close modal
							})
							.catch((err) => {
								console.log("ERROR", err);
							});
					})
					.catch((err) => {
						console.error("signout error: ", err);
					});
			// })
			// .catch((err) => {
			// 	console.error("signout error: ", err);
			// });
	};

	const token = extractTokenFromUrl();

  return (
    <div>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <HeaderTitle brandingTitle={brandingTitle} />
          </div>
          <div>
            <Box display="flex">
						<Tooltip title="refresh room"
              >
                <div
                  tabIndex={0}
                //   className={user.loggedIn ? classes.iconButtonDisabled : classes.iconButton}
				className={token || authState?.loggedIn ? classes.iconButtonDisabled : classes.iconButton}
                  disabled={
										// user.loggedIn
										token || authState?.loggedIn
                  }
                  aria-label="refresh room"
                  color="inherit"
                  autoFocus={false}
                  onClick={(e) => {
                    handleLogout()
                    document.activeElement.blur();
                  }}
                >
                  <img
                    src={RefreshIcon}
                    className={classes.icon}
                    alt="refresh icon"
                    aria-hidden="true"
                  />
                </div>
              </Tooltip>
              <Tooltip title="accessibility options"
              >
                <div
                  tabIndex={0}
                  className={view === VIEW.ACCESSIBILITY ? classes.iconButtonDisabled : classes.iconButton}
                  disabled={
                    view === VIEW.ACCESSIBILITY
                      ? true
                      : false
                  }
                  aria-label="accessibility options"
                  color="inherit"
                  autoFocus={false}
                  onClick={(e) => {
                    if (view !== VIEW.ACCESSIBILITY)
                      handleAccessibilityButton()
                    document.activeElement.blur();
                  }}
                >
                  <img
                    src={TextIcon}
                    className={classes.icon}
                    alt="text icon"
                    aria-hidden="true"
                  />
                </div>
              </Tooltip>
              {/* <Tooltip title={user.loggedIn ? "logout" : "login"}> */}
			  {/* <Tooltip title={user ? user.isAnonymous ? "login" : "logout" : "login"}> */}
			<Tooltip title={authState ? authState.isAnonymous ? "login" : "logout" : "login"}>
                {/* <IconButton */}
                <div
                  tabIndex={0}
                  // disableFocusRipple
                  // disabled={
                  // 	view === VIEW.SETTINGS ||
                  // 		view === VIEW.LOGIN
                  // 		? true
                  // 		: false
                  // }
                  className={token || modalOpen || view === VIEW.LOGIN ? classes.iconButtonDisabled : classes.iconButton}
                  disabled={token || modalOpen || view === VIEW.LOGIN ? true : false}
                  onClick={() => {
                    handleClickOpen()
                    document.activeElement.blur();
                  }}
                  aria-label="login"
                  color="inherit"
                  autoFocus={false}
                >
                  <img
                    src={UserIcon}
                    className={classes.icon}
                    alt="user icon"
                    aria-hidden="true"
                  />
                  {/* </IconButton> */}
                </div>
              </Tooltip>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <UserModal
        firestore={firestore}
        setModalOpen={setModalOpen}
        open={modalOpen}
        onClose={handleClose}
		userDB={user}
		chatbotId={chatbotId}
      />
    </div>
  );
}
