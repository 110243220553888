import { firestore } from "../services/firebase/firebase";
import "firebase/firestore";

async function messageToFirestore(messagesArr, fallbackMessage, options, provider, tmpRoomId, chatbotName) {
	//If there is one or more greetings in BQ use them
	if (messagesArr.length > 0) {
		let i = 0;
		for (const msg of messagesArr) {
			await firestore.collection("rooms").doc(tmpRoomId).collection("messages").add(
				//if last message add options else not
				i === messagesArr.length - 1 ?
					{
						text: msg,
						uid: provider,
						createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
						options: options,
						greeting: true,
						chatbotName: chatbotName,
					}
					:
					{
						text: msg,
						uid: provider,
						createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
						greeting: true,
						chatbotName: chatbotName,
					}
			);
			i++;
		}
	} else {
		//else if not messages in BQ use the hardcoded one
		await firestore.collection("rooms").doc(tmpRoomId).collection("messages").add({
			text: fallbackMessage,
			uid: provider,
			createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
			options: options,
			greeting: true,
			chatbotName: chatbotName,
		});
	}
}

//CHATBOTS GREETINGS AND OPTIONS FOR APP.JS - REQUIRED
export default async function writeGreetingsOptions(userId, chatbotId) {
	const tmpRoomId = userId + "***" + chatbotId
	const agent = null; //data.defaultAgent;
	const chatbotid = chatbotId ? chatbotId : "1001"
	console.log("CHATBOT GREETINGS AND OPTIONS API")
	console.log("userId", userId);
	console.log("chatbotId", chatbotId);
	console.log("tmpRoomId", tmpRoomId);

	try {
		firestore
			.collection("chatbots")
			.doc(chatbotid)
			.get()
			.then(async (res) => {
				const chatbotDocVal = res.data()
				const chatbotName = chatbotDocVal.name;
				const greetings = chatbotDocVal.greetings;
				const options = chatbotDocVal.options;
				console.log("greetings", greetings);
				console.log("options", options);
				console.log("GET CHATBOT INFO IN WRITE GREETINGS", chatbotDocVal);
				firestore
					.collection("rooms")
					.doc(tmpRoomId)
					.set({
						pendingMessages: chatbotDocVal.greetings.length - 1,
					}, { merge: true });

				//If the last message is a greeting from the same chabot do not add it again
				const messagesRef = firestore
					.collection("rooms")
					.doc(tmpRoomId)
					.collection("messages");
				const oldMsgs = await messagesRef
					.orderBy("createdAt", "desc")
					.limit(1)
					.get();
				// Check if there is a document
				if (!oldMsgs.empty) {
					// Access the first document in the result set
					const firstDocument = oldMsgs.docs[0];

					// Access the data of the document
					const data = firstDocument.data();
					//if last message was a greeting from the same chatbot just return
					console.log("data.greeting:", data.greeting);
					if (data.greeting) {
						return
					}
					// Log all key-value pairs
					console.log("Document data:", data);
				} else {
					console.log("No documents found.");
				}

				const fallbackMsg = "👋 Welcome! I quickly answer common questions or explore our resources for anything else. Ask away!"; //"Welcome!"
				messageToFirestore(chatbotDocVal.greetings, fallbackMsg, chatbotDocVal.options, agent, tmpRoomId, chatbotName)
			}).catch((error) => {
				// An error ocurred
				console.log("writeGreetingsOptions ERRR", error)
			});
	}
	catch (err) {
		console.log("ERROR snap", err);
	};
};
