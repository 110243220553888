import { VIEW } from "../../constants/view.constants";
import { VISIBILITY } from "../../constants/visibility.constants";
import { FONT_SIZE } from "../../constants/fontSize.constants";

export const initialState = {
	view: VIEW.CHATROOM,
	fontSize: FONT_SIZE.STANDARD,
	visibility: VISIBILITY.STANDARD,
	colorTheme: "DEFAULT",
	colorSettings: {
		primary: "#014ee2",
		accent: "#0045ca",
		contrast: "#03266d",
	},
	user: {
		id: null,
		loggedIn: false,
		name: null,
	},
	admin: {
		adminUser: false,
		feedbackOn: false,
	},
	chatOpen: false,
	clientId: null,
	loggingOut: false,
	// inputFocused: false,
};

const reducer = (state, action) => {
	// console.log("Action made: ", action);
	switch (action.type) {
		case "SET_VIEW":
			return {
				...state,
				view: action.view,
			};
		case "SET_FONT_SIZE":
			return {
				...state,
				fontSize: action.fontSize,
			};
		case "SET_VISIBILITY":
			return {
				...state,
				visibility: action.visibility,
			};
		case "SET_COLOR_THEME":
			return {
				...state,
				colorTheme: action.colorTheme,
			};
		case "COLOR_SETTINGS":
			return {
				...state,
				colorSettings: action.colorSettings,
			};
		case "USER":
			return {
				...state,
				user: action.user,
			};
		// case "CHAT_OPEN":
		//   return {
		//     ...state,
		//     chatOpen: action.chatOpen,
		//   };
		case "CHAT_PANEL_STATE":
			return {
				...state,
				loaded: action.loaded,
			};
		case "CLIENT_ID":
			return {
				...state,
				clientId: action.clientId,
			};
		case "ADMIN_SETTINGS":
			return {
				...state,
				admin: action.admin,
			};
		// case "INPUT-FOCUSED":
		// 	return {
		// 		...state,
		// 		inputFocused: action.inputFocused,
		// 	};
		case "LOGGING_OUT":
			return {
				...state,
				loggingOut: action.loggingOut,
			};

		default:
			return state;
	}
};

export default reducer;
