const applyCustomColorSettings = (colorType, color) => {
	const updateStyle = document.body.style;

	if (colorType === "PRIMARY") {
		updateStyle.setProperty("--active-brand-color", color);
		updateStyle.setProperty("--active-msg-bg", color);
	} else if (colorType === "ACCENT") {
		updateStyle.setProperty("--active-msg-bg", color);
	} else {
		updateStyle.setProperty("--active-high-contrast", color);
	}
};

const resetDefaultColors = () => {
	const updateStyle = document.body.style;
	updateStyle.setProperty("--active-brand-color", "var(--default-brand-color)");
	updateStyle.setProperty("--active-msg-bg", "var(--default-user-msg-bg)");
	updateStyle.setProperty(
		"--active-high-contrast",
		"var(--default-high-contrast)"
	);
};

module.exports = {
	applyCustomColorSettings,
	resetDefaultColors,
};
