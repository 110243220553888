import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import ResponseButton from "./ResponseButton";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore, auth } from "../services/firebase/firebase";

const useStyles = makeStyles({
	container: {
		display: "flex",
		flexWrap: "wrap",
		margin: "12px 30px 0 30px",
		justifyContent: "flex-start",
	},
});

export default function ResponseBtnGroup({
	options,
	userId,
	messageId,
	messagesRef,
	online,
	linkMap,
	chatbotIsDraft,
	switchOptions,
	metadata,
	chatbotId
}) {
	const classes = useStyles();
	const [selected, setSelected] = useState(false);
	const [authState, authLoading, authError] = useAuthState(auth);
	const submitResponse = async (messageId, value) => {
		setSelected(value);
		
		// console.log("submitting message:", value);

		// Submit response on button click and update the options to null in the DB
	
		await messagesRef
			.add({
				text: [value],
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
				uid: authState?.uid + "***" + chatbotId, //userId,
				photoUrl:
					"https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
				switchOptions: switchOptions,
				availabilityStep: metadata?.feedBackAvailabiliy ? 1 : 0,
			})
			.then((res) => {
				firestore
					.collection("rooms")
					.doc(userId)
					.collection("messages")
					.doc(messageId)
					.update({ options: null });
			})
			.catch((err) => console.error(err));
	};

	return (
		<div className={classes.container}>
			{options &&
				options.map((option) => (
					<ResponseButton
						key={option}
						value={option}
						messageId={messageId}
						submitResponse={submitResponse}
						selected={selected}
						online={online}
						linkMap={linkMap}
						chatbotIsDraft={chatbotIsDraft}
					>
						{/* Update 1 Nov 2022
						At this time the options are returing a text inside a span tag
						To remove the html tag used .replace
						*/}
						{option.replace("<span>", "").replace("</span>", "")}
					</ResponseButton>
				))}
		</div>
	);
}
