import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({});

theme.props = {
	MuiButton: {
		disableRipple: true,
		disableFocusRipple: true,
	},
	MuiInput: {
		disableUnderline: true,
	},
	MuiIconButton: {
		disableFocusRipple: true,
		disableRipple: true,
	},
};

// apply CSS variables to global styling

theme.overrides = {
	MuiAppBar: {
		colorPrimary: {
			backgroundColor: "var(--active-brand-color)",
		},
	},
	MuiToolbar: {
		root: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			color: "white",
			height: "100%",
			width: "100%",
			padding: "0px",
		},
	},
	MuiSwitch: {
		switchBase: {
			// Controls default (unchecked) color for the thumb
			color: "var(--color-light-grey)",
		},
		colorSecondary: {
			"&$checked": {
				// Controls checked color for the thumb
				color: "var(--active-brand-color)",
			},
		},
		track: {
			// Controls default (unchecked) color for the track
			opacity: 0.2,
			backgroundColor: "var(--color-light-grey)",
			"$checked$checked + &": {
				// Controls checked color for the track
				opacity: 0.7,
				backgroundColor: "var(--active-brand-color)",
			},
		},
	},

	MuiButton: {
		root: {
			fontSize: "var(--active-font-size)",
			fontFamily: `'averta std', sans-serif`,
			background: "white",
			color: "var(--active-msg-bg)",
			border: "1px solid var(--active-msg-bg)",
			borderRadius: "42px",
			textTransform: "none",
			transition: "none",
			padding: "5px 10px",
			"&:hover": {
				opacity: "0.9",
				backgroundColor: "white",
			},
			"&:focus": {
				outline: "none",
				// boxShadow: "0 0 0px 2px  var(--active-brand-color)", //dec14
				boxShadow: `0 0 0px 2px var(--active-brand-color),
				-1px 0 0px 1px var(--active-brand-color),
				1px 0 0px 1px var(--active-brand-color),
				0 -1px 0px 1px var(--active-brand-color),
				0 1px 0px 1px var(--active-brand-color)`,
			},
			"&:disabled": {
				borderColor: "var(--color-light-grey)",
			},
		},
	},
	MuiIconButton: {
		root: {
			"&:hover": {
				opacity: "0.8",
				backgroundColor: "none",
			},
		},
	},
	MuiDivider: {
		root: {
			backgroundColor: "var(--color-light-grey)",
		},
	},
	MuiBackdrop: {
		root: {
			top: "75px",
			bottom: "80px",
			borderRadius: "0 0 6px 6px",
			backgroundColor: "rgba(250,250, 250, 0.8)",
		},
	},

	MuiTypography: {
		colorPrimary: {
			color: "var(--color-dark-grey)",
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
	},
};

export default theme;
