const applyClientBranding = (colorSettings) => {
	const updateStyle = document.body.style;
	updateStyle.setProperty("--custom-brand-color", colorSettings.primary);
	updateStyle.setProperty("--custom-accent-color", colorSettings.accent);
	updateStyle.setProperty("--custom-contrast-color", colorSettings.contrast);
	updateStyle.setProperty("--active-brand-color", "var(--custom-brand-color)");
	updateStyle.setProperty("--active-msg-bg", "var(--custom-accent-color)");
	updateStyle.setProperty(
		"--active-high-contrast",
		"var(--custom-contrast-color)"
	);
};

module.exports = {
	applyClientBranding,
};
