import { useStateValue } from "../context/state/provider";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { resizeGlobalFonts } from "../helpers/accessibilityHelpers";
import { FONT_SIZE } from "../constants/fontSize.constants";

const useStyles = makeStyles({
	root: {
		width: "105px",
		minWidth: "fit-content",
		maxWidth: "33vw",
		height: "42px",
		margin: "5px",
		transition: "background 0.3s ease-in",
		"&:focus": {
			border: "2px solid var(--active-brand-color)",
		},
	},
	selected: {
		background: "var(--active-msg-bg)",
		color: "white",
		border: "2px solid var(--active-brand-color)",
		outline: "none",
		"box-shadow": "0 0 0px 2px var(--active-brand-color), -1px 0 0px 1px var(--active-brand-color), 1px 0 0px 1px var(--active-brand-color), 0 -1px 0px 1px var(--active-brand-color), 0 1px 0px 1px var(--active-brand-color)", //add mar01
		"&:hover": {
			background: "var(--active-msg-bg)",
			opacity: "0.9",
		},
	},
	buttonRow: {
		display: "flex",
		margin: "8px auto",
	},
	small: {
		fontSize: "var(--small-font-size)",
	},
	standard: {
		fontSize: "var(--default-font-size)",
	},
	large: {
		fontSize: "var(--large-font-size)",
	},
	"@media (max-width: 300px)": {
		small: {
			fontSize: "calc(var(--small-font-size) - 2px)",
		},
		standard: {
			fontSize: "calc(var(--default-font-size) - 2px)",
		},
		large: {
			fontSize: "calc(var(--large-font-size) - 2px)",
		},
	},
	"@media (max-width: 260px)": {
		buttonRow: {
			flexWrap: "wrap",
		},
	},
});

export default function FontButtons() {
	const [{ fontSize }, dispatch] = useStateValue();
	const classes = useStyles();

	const toggleFontSize = (size) => {
		dispatch({
			type: "SET_FONT_SIZE",
			fontSize: size,
		});
		localStorage.setItem("fontSizeSetting", size);
		resizeGlobalFonts(size);
	};

	return (
		<div className={classes.buttonRow}>
			<Button
				className={
					fontSize === FONT_SIZE.SMALL
						? `${classes.root} ${classes.small} ${classes.selected}`
						: `${classes.root} ${classes.small}`
				}
				onClick={() => toggleFontSize(FONT_SIZE.SMALL)}
			>
				Small
			</Button>
			<Button
				className={
					fontSize === FONT_SIZE.STANDARD
						? `${classes.root} ${classes.standard} ${classes.selected}`
						: `${classes.root} ${classes.standard}`
				}
				onClick={() => toggleFontSize(FONT_SIZE.STANDARD)}
			>
				Standard
			</Button>
			<Button
				className={
					fontSize === FONT_SIZE.LARGE
						? `${classes.root} ${classes.large} ${classes.selected}`
						: `${classes.root} ${classes.large}`
				}
				onClick={() => toggleFontSize(FONT_SIZE.LARGE)}
			>
				Large
			</Button>
		</div>
	);
}
