import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
	root: {
    maxWidth: 345,
		minWidth: 345,
		"&:hover": {
			border: "#2E56F4",
			cursor: "pointer",

			"& button": {
				"&:hover": {
					opacity: "0.8",
				}
			}
		}
  },
  media: {
    height: 200,
  },
	card: {
		width: "100%",
		borderRadius: "4px",
		border: "1px",
		boxSizing: "border-box",

		"&:hover": {
			border: "#2E56F4",
			cursor: "pointer",

			"& button": {
				"&:hover": {
					opacity: "0.8",
				}
			}
		}
	},
	cardBody: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		// height: "100px",
		margin: "0",
		padding: "0",
		border: "none",
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		backgroundColor: "#E2E6F5",
		backgroundSize: "cover",
		boxSizing: "border-box",
	},
	cardFooter: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontWeight: "bold",
		padding: "10.5px 16px",
		color: "black",
		// backgroundColor: "rgba(118, 136, 144, 0.1)",
		boxSizing: "border-box",
		lineHeight: "21.6px",
		letterSpacing: "0.01071em",
		borderBottomLeftRadius: "inherit",
		borderBottomRightRadius: "inherit",
	},
	modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContent: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflow: "auto",
    "& img": {
      width: "100%",
      height: "auto"
    }
  },
  image: {
    width: "100%",
    height: "auto",
    cursor: "pointer"
  }
});

export default function CustomCard({
	text,
	href,
	metatag,
	isMap,
	mapSVG,
	setLastMsgPaint,
	isLast,
	isIkea,
	isStore,
	linkMap,
	onImageLoad,
}) {
	const classes = useStyles(metatag);
	const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	const [imageLoaded, setImageLoaded] = useState(false);
	const handleImageLoad = () => {
		if (!imageLoaded && onImageLoad) {
			onImageLoad(); // Call the callback function when the image is loaded
			setImageLoaded(true);
		}
	};

	const handleImageError = () => {
		if (!imageLoaded && onImageLoad) {
			onImageLoad(); // Call the callback function even if the image fails to load
			setImageLoaded(true);
		}
	};

	//Image logic to detect when the image load or error (not loaded)
	//to make the messages scroll to the end
	useEffect(() => {
		if (setLastMsgPaint) {
			setLastMsgPaint(true)
		}
	}, [setLastMsgPaint])

	//SVG map
	let newSVG = "";
	if (isMap === true) {
		newSVG = mapSVG[0]?.mapSVG[0].replace(/[\\]/g, '');
		newSVG = newSVG.replace("xmlns:xlink", "xmlnsXlink");
	}

	//Time function
	const date = new Date()

	console.log("href", href);
	console.log("metatag", metatag);
	console.log("text", text);
	console.log("isMap", isMap);
	console.log("mapSVG", mapSVG);

	// return (
	// 	<>
	// 		{/* STORE CARD */}
	// 		{/* <a target="_blank" rel="noreferrer" href={href} style={{ textDecoration: "none" }}> */}

	// 		<div 
	// 			className={`${classes.card}`} 
	// 			style={{ height: "200px", width: "auto", backgroundColor: '#E2E6F5' }}
	// 			onClick={handleOpen}>
	// 			<div
	// 				className={`${classes.cardBody}`}
	// 				style={{
	// 					// display: "inline-block",
	// 					width: "100%",
	// 					maxWidth: "500px",
	// 					// maxHeight: "150px",
	// 					// textAlign: "center",
	// 					// backgroundColor: "white",
	// 					backgroundColor: '#E2E6F5',
	// 					// paddingTop: "100%", 
	// 					position: "relative",
	// 					height: "200px", // Set a fixed height for the card
	// 					// width: "auto", // Allow the width to adjust automatically
	// 					// "background-image": `url(${metatag[0].image})`, // Set the background image
	// 					// backgroundSize: "cover", // Cover the entire card with the image
	// 					// backgroundPosition: "center", // Center the image within the card
	// 				}}>
	// 				{/* <img ref={imgEl} alt="" src={metatag[0].image ? `${metatag[0].image}` : 'https://picsum.photos/200/300'} style={{width: '100%'}}/> */}
	// 				{/* {metatag[0].image &&  */}
	// 				<img
	// 					// ref={isLast ? imgEl : null} 
	// 					// alt="" src={metatag[0].image} style={{ width: 'auto', height: '100%' }} />}
	// 					alt=""
	// 					src={metatag[0].image}
	// 					style={{
	// 						// width: '-webkit-fill-available'
	// 						position: "absolute",
	// 						top: 0,
	// 						left: 0,
	// 						// width: "100%",
	// 						height: "100%",
	// 						objectFit: "cover", // Maintain aspect ratio and cover container
	// 					}}
	// 					onLoad={handleImageLoad} // Call handleImageLoad when the image is loaded
	// 					onError={handleImageError} // Call handleImageError if the image fails to load
	// 				/>
	// 				{/* } */}
	// 			</div>
	// 			<div className={`${classes.cardFooter}`} style={{ textDecoration: "none" }} >
	// 				{metatag[0].title ? metatag[0].title : text}
	// 				{/* <p style={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', fontWeight: 'normal'}}>{metatag[0].description}</p> */}
	// 				{metatag[0].ikea && <p style={{ textDecoration: "underline", fontWeight: "normal", margin: "0px" }}></p>}
	// 				{!metatag[0].ikea &&
	// 					(
	// 						<>
	// 							{/* <p style={{display: 'flex'}}><span><img src={bluePhone} alt="phone-icon" style={{maxHeight: "20px", maxWidth: "20px", marginRight: "5px"}}/> </span>{metatag[0].phoneNumber}</p> */}
	// 							{metatag[0].storehours && date.getDay() - 1 < 0 ?
	// 								(
	// 									<p key={"day" + metatag[0].storehours[6].day} style={{ margin: "0px" }}>{metatag[0].storehours[6].day}: <span style={{ fontWeight: "normal" }}>{metatag[0].storehours[6].hours}</span></p>
	// 								)
	// 								:
	// 								(
	// 									<p key={"day" + metatag[0].storehours[date.getDay() - 1].day} style={{ margin: "0px" }}>{metatag[0].storehours[date.getDay() - 1].day}: <span style={{ fontWeight: "normal" }}>{metatag[0].storehours[date.getDay() - 1].hours}</span></p>
	// 								)
	// 							}
	// 						</>
	// 					)}
	// 			</div>
	// 		</div>
	// 		<Modal open={open} onClose={handleClose} className={classes.modal}>
  //       <div className={classes.modalContent}>
	// 				<img src={metatag[0].image} alt="step" />
  //         {/* <Button onClick={handleClose}>Close</Button> */}
  //       </div>
  //     </Modal>
	// 	</>
	// )

	return (
    <div>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={metatag[0].image}
          title={text}
          onClick={handleOpen}
        />
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="h2">
            {text}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {metatag[0].description}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          </Typography> */}
        </CardContent>
      </Card>
			<Modal open={open} onClose={handleClose} className={classes.modal}>
         <div className={classes.modalContent}>
	 				<img src={metatag[0].image} alt="step" />
           {/* <Button onClick={handleClose}>Close</Button> */}
         </div>
       </Modal>
    </div>
  );

}
